.dpt-dropdown-container {
  position: relative; }

.dpt-dropdown {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .dpt-dropdown i {
    margin-right: 0; }
  .dpt-dropdown .dpt-dropdown-left-icon {
    margin-right: 10px; }

.dpt-dropdown-list {
  background-color: #f9f9f9;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
  top: 40px;
  right: 10px;
  min-width: 200px;
  z-index: 10000;
  display: flex;
  flex-direction: column; }
  .dpt-dropdown-list li {
    padding: 20px;
    cursor: pointer; }
    .dpt-dropdown-list li:hover {
      background-color: #003d6d;
      color: #fff; }

.dpt-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5500; }
