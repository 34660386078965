select {
  width: 100%;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  height: 44px;
  padding: 0 8px;
  font-size: 16px;
  margin-bottom: 18px;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-color: #fff;
  border: none; }
  select:focus {
    outline: none; }
  select::placeholder {
    color: #D9D9D9; }
  select:disabled {
    cursor: not-allowed;
    background-color: #efefef; }
  select.invalid {
    border-bottom: 1px solid red !important; }
