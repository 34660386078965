.dpt-searchable-select {
  position: relative; }

.dpt-searchable-select-list {
  background-color: #fff;
  position: absolute;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  top: 44px;
  z-index: 50000;
  min-height: inherit !important; }
  .dpt-searchable-select-list li {
    background-color: #fff;
    padding: 15px;
    border-bottom: 2px solid #F7F7F7; }
