label {
  font-size: 1rem;
  margin-bottom: 15px;
  display: block;
  color: #444;
  font-weight: 500; }

.required {
  color: red;
  margin-left: 5px; }
