.btn {
  background-color: #175072;
  cursor: pointer;
  border-radius: 3px;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: max-content;
  color: #fff; }
  .btn span {
    margin: 0; }
  .btn.disabled {
    background-color: #eee !important;
    color: #444 !important; }

.icon-btn i {
  margin-right: 10px; }

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: '#fff'; }

.btn-loader {
  display: 'flex';
  justify-content: center;
  align-items: 'center';
  color: '#fff';
  margin-right: 20px; }
