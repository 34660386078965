.modal-overlay {
  min-height: 100vh;
  background-color: black;
  opacity: 0.7;
  position: fixed;
  z-index: 1000;
  width: 100%;
  left: 0;
  top: 0; }

.modal {
  background-color: #fafafa;
  width: 70%;
  border-radius: 5px;
  z-index: 200000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  display: grid;
  grid-template-rows: 60px 1fr 70px;
  max-width: 1000px; }
  .modal .modal-content {
    width: 100%;
    margin: 0px auto;
    padding: 20px;
    max-height: 80vh;
    overflow: auto; }
  .modal .modal-footer {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    height: 70px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .modal .modal-footer .btn {
      margin-right: 10px;
      min-width: 150px;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
      background-color: #000;
      color: #fff;
      height: max-content;
      min-height: 44px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .modal .modal-footer .btn:last-of-type {
        margin-right: 0;
        background-color: #000;
        color: #fff; }
      .modal .modal-footer .btn.disabled {
        background-color: #eee !important;
        cursor: not-allowed; }
  .modal .modal-header {
    background-color: #000;
    display: flex;
    align-items: center;
    height: 55px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    justify-content: space-between;
    padding: 20px; }
    .modal .modal-header.error {
      background-color: red; }
    .modal .modal-header i {
      margin-right: 20px;
      font-size: 1rem;
      color: #fff;
      cursor: pointer; }
    .modal .modal-header .close {
      width: 40px;
      cursor: pointer;
      height: 40px;
      position: absolute;
      top: 15px;
      right: 0;
      background-repeat: no-repeat; }
    .modal .modal-header h1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      justify-content: space-between;
      color: #fff;
      margin-top: 0;
      font-weight: inherit;
      font-size: 1.4rem;
      margin-bottom: 0;
      border-bottom: none; }
      .modal .modal-header h1 span, .modal .modal-header h1 i {
        margin: 10px; }

.dpt-modal-without-footer {
  grid-template-rows: 60px 1fr; }

.dpt-modal-with-footer {
  grid-template-rows: 60px 1fr 70px; }

#warningModal {
  z-index: 15005;
  width: 70%;
  bottom: auto; }
  #warningModal .modal-content p {
    line-height: 1.3;
    text-align: center; }
  #warningModal .modal-header {
    background-color: #8b0000; }
  #warningModal .modal-footer .btn {
    background-color: #8b0000 !important; }
    #warningModal .modal-footer .btn:last-of-type {
      background-color: #000; }

@media only screen and (max-width: 768px) {
  #warningModal {
    z-index: 15005;
    height: 200px;
    width: max-content;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    margin-left: 0 !important; }
  .modal {
    margin-left: 0 !important;
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
    left: 0%;
    border-radius: 0;
    top: 0; }
    .modal .modal-content {
      max-height: 95vh; }
    .modal .modal-footer .btn {
      min-width: inherit; }
  #warningModal {
    top: 0;
    left: 0;
    width: 100%; } }
