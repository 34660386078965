.errorInfo {
  color: #fff;
  background-color: #ff3c3c;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px; }
  .errorInfo .errorTitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px; }
  .errorInfo strong {
    font-weight: bold;
    color: #fff; }
  .errorInfo ul, .errorInfo li {
    list-style: square !important;
    padding: 5px; }
  .errorInfo li {
    margin-left: 15px; }
