.dpt-checkbox {
  min-height: 44px;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .dpt-checkbox span {
    line-height: 40px; }
  .dpt-checkbox .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .dpt-checkbox .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .dpt-checkbox .checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #eee;
    left: 0;
    top: -5px; }
  .dpt-checkbox .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc; }
  .dpt-checkbox .checkbox-container input:checked ~ .checkmark {
    background-color: #2e6da4; }
  .dpt-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .dpt-checkbox .checkbox-container input:checked ~ .checkmark:after {
    display: block; }
  .dpt-checkbox .checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
