$main-background: #F9F9F9;

$aside-background: #000;
$aside-color: #fff;

$highlight: #00A6E9;

$footer-background: #fff;
$footer-color: #504d4d;

$main-color: #504d4d;
$buttons: #000;
$accent-color: #fff;


