.input {
  position: relative; }
  .input .maxLengthInput {
    padding-right: 60px !important; }
  .input .maxLength {
    top: 0;
    right: 0;
    color: #bdbdbd;
    position: absolute;
    height: 42px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; }

.passwordInput {
  display: flex;
  align-items: center; }
  .passwordInput .input {
    width: 100% !important; }

.passwordBtn {
  height: 44px;
  margin: 0;
  background-color: #fff;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 44px;
  border-left: none;
  cursor: pointer;
  border-left: 1.5px solid #f7f7f7;
  margin-bottom: 18px; }

input:not([type=checkbox]):not([type=radio]):not([type=range]) {
  width: 100%;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  margin: 0 0 5px 0;
  height: 44px;
  padding: 0 8px;
  font-size: 16px;
  margin-bottom: 18px;
  border-radius: 2px; }
  input:not([type=checkbox]):not([type=radio]):not([type=range])::placeholder {
    color: #D9D9D9; }
  input:not([type=checkbox]):not([type=radio]):not([type=range]):disabled {
    background-color: #efefef; }

.invalid {
  border-bottom: 1px solid red !important; }

.inputError {
  margin-bottom: 20px;
  color: #ff3b3c; }
