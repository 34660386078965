.infoInput {
  position: relative; }
  .infoInput .tooltipIcon {
    height: 40px;
    bottom: 20px;
    top: 0;
    font-size: 22px;
    color: #e4e4e4;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer; }
